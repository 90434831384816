.Question {
  height: 100%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;

  max-width: 50rem;
  width: calc(100vw);
  margin: 0 auto;
}

.Question__prompt {
  font-size: 2.2rem;
  text-align: center;
  padding: 1rem 1rem;
}

.Question__prompt--persian {
  font-size: 2.6rem;
  font-weight: 400;
}

.Question__count {
  position: absolute;
  opacity: 0.25;
  padding: 1.5rem 0.5rem;
}
