.Import {
  padding-top: 5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Import__form {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}

.Import__textarea {
  width: 100%;
  flex-grow: 1;
}
