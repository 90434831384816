.Suggestions {
  padding-top: 5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Suggestions__content {
  margin-top: 2rem;
  width: 100%;
  /* max-width: 100rem; */
  /* padding: 0 2rem; */
}

.Suggestions__table {
  overflow: auto;
}

.Suggestions__row {
  /* width: 100%; */
  width: fit-content;
  min-width: 100%;
  display: flex;
  height: 2.5rem;
  align-items: center;
}

.Suggestions__row:nth-child(odd) {
  background-color: var(--persian-yellow-light);
}

.Suggestions__row:hover {
  background-color: var(--persian-pale-blue);
}

.Suggestions__cell {
  flex-basis: 25%;
  flex-grow: 1;
  min-width: 10rem;
}
