.Export {
  padding-top: 5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Export__toolbar {
  padding: 0.5rem;
  width: 100%;
  text-align: left;
}

.Export textarea {
  width: 100%;
  height: 100%;
}
