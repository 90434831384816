.Home h1 {
  font-weight: 500;
  font-size: 3rem;
  margin: 1rem 0;
  color: white;
  text-shadow: 0rem 0rem 1.5rem black;
}

.Home h2 {
  font-weight: 400;
  font-size: 2rem;
  margin: 1rem 0;
  color: var(--persian-white);
  text-shadow: 0rem 0rem 1.5rem black;
}

.Home__top {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  /* justify-content: center; */

  padding: 0 2rem;
  height: calc(100vh - 4rem);

  background: url('./landing-blur-small.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 65%;
  background-position-y: top;
}

.Home__title {
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;
}

.Home__button {
  border: 0.2rem solid var(--persian-white);
  color: var(--persian-white);
  margin: 2rem 0;
  padding: 0.7rem;
  border-radius: 0.4rem;
  display: block;
  width: fit-content;
  /* float: right; */
  text-shadow: 0rem 0rem 1.5rem black;
}

.Home__button:visited {
  color: var(--persian-white);
}

.Home__button:active,
.Home__button:hover {
  color: var(--persian-yellow);
  border-color: var(--persian-yellow);
}

.Home__middle {
  padding: 1rem 2rem;
  background-color: var(--persian-teal);
  color: var(--persian-white);
}

.Home__middle a {
  color: var(--persian-yellow);
}

.Home__middle a:hover {
  color: var(--persian-yellow);
}

.Home__bottom {
  padding: 1rem 2rem;
  color: var(--persian-black);
}

@media only screen and (min-width: 700px) {
  .Home__top {
    background: url('./landing-blur-medium.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 20%;
  }
}
