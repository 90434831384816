/***********************************
 * Colours
 **********************************/

:root {
  /* base colour defintions */
  --persian-grey: hsl(220, 26%, 50%);
  --persian-grey-dark: hsl(220, 26%, 28%);
  --persian-black: hsl(220, 26%, 14%);
  --persian-white: hsl(220, 26%, 97%);

  --persian-blue: hsl(196, 100%, 33%);
  --persian-blue-light: hsl(196, 100%, 40%);
  --persian-blue-dark: hsl(196, 100%, 26%);

  --persian-pale-blue: hsl(196, 53%, 70%);

  --persian-teal: hsl(176, 59%, 30%);
  --persian-teal-light: hsl(176, 59%, 37%);
  --persian-teal-dark: hsl(176, 59%, 23%);

  --persian-green: hsl(154, 28%, 57%);

  --persian-yellow: hsl(57, 45%, 76%);
  --persian-yellow-light: hsl(57, 45%, 93%);
  /* --persian-yellow-light: hsl(57, 45%, 86%); */

  --persian-red: hsl(5, 63%, 51%);
  --persian-red-light: hsl(5, 63%, 58%);
  --persian-red-dark: hsl(5, 63%, 44%);

  /* media query screen sizes */
  --small-screen: 460px;
  --medium-screen: 700px;
  --large-screen: 1000px;

  /* element definitions */
  --box-shadow: rgba(0, 0, 0, 0.4);
  --text-color: var(--persian-white);
  --background-color: #eee;
}

/***********************************
 * Base style
 **********************************/

html,
body,
#root,
.Routes {
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Helvetica Neue', Arial, sans-serif;

  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  color: var(--persian-black);
}

a {
  color: var(--persian-black);
  text-decoration: none;
}

p {
  line-height: 2.2rem;
  margin: 2rem auto;
  max-width: 50rem;
}

a:hover,
a:visited {
  color: var(--persian-black);
}

label {
  display: block;
  font-size: 1.2rem;
  font-weight: 400;
}

div.error {
  font-size: 1.2rem;
  color: var(--persian-red);
}

.buttonRow {
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
}

input[type='text'],
input[type='password'],
input[type='email'] {
  width: 100%;
  padding: 0.5rem;
  font-size: 1.6rem;
  font-weight: 300;
  border: 0.1rem solid #d9d9d9;
  border-radius: 0.3rem;
}

input[type='text']:hover,
input[type='text']:focus,
input[type='password']:hover,
input[type='password']:focus,
input[type='email']:hover,
input[type='email']:focus {
  outline: none;
  border-color: var(--persian-blue);
}

input.error,
input.error:hover,
input.error:focus {
  border-color: var(--persian-red);
}

input[type='checkbox'] {
  display: inline-block;
}

input[type='checkbox'] + label {
  margin-left: 1rem;
  display: inline-block;
}

select {
  padding: 0.6rem;
  border: 1px solid #d9d9d9;
  color: var(--persian-black);
  border-radius: 0.3rem;
  font-size: 1.4rem;
  height: 3.5rem;
  width: 100%;

  /* remove background gradient on safari 
  /* https://stackoverflow.com/a/38764639/2620595 */
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+);
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
  background-color: white;
  appearance: none;
}

select:hover,
select:focus,
textarea {
  border-color: var(--persian-blue);
  outline: none;
}

textarea {
  border-radius: 0.5rem;
}

/***********************************
 * Media queries
 **********************************/

.medium {
  display: none;
}

.large {
  display: none;
}

.x-large {
  display: none;
}

@media screen and (min-width: 460px) {
  .medium {
    display: block;
  }
}

@media screen and (min-width: 700px) {
  .large {
    display: block;
  }
}

@media screen and (min-width: 1000px) {
  .x-large {
    display: block;
  }
}

/***********************************
 * Button
 **********************************/
button.button {
  background-color: var(--persian-blue);
  border: 0.1rem solid var(--persian-blue-dark);
  color: var(--persian-white);
  border-radius: 0.3rem;
  padding: 0.6rem 1rem;
  font-size: 1.4rem;
  font-weight: 300;
  margin: 0;
}

button:focus {
  outline: none;
}

button.button:hover,
button.button:active,
button.button:focus {
  background-color: var(--persian-blue-light);
  border-color: var(--persian-blue);
}

button.button.affirmative {
  background-color: var(--persian-teal);
  border-color: var(--persian-teal-dark);
}

button.button.negative {
  background-color: var(--persian-red);
  border-color: var(--persian-red);
}

button.button[disabled] {
  background-color: #bbb;
  border-color: #999;
}

button.button.secondary {
  border-color: var(--persian-blue);
  background-color: var(--persian-white);
  color: var(--persian-blue-dark);
}

/* button as link */

button.link {
  background: none;
  border: none;
  font-size: 1.6rem;
  color: var(--persian-blue);
  cursor: pointer;
}

button.link:hover,
button.link:active,
button.link:focus {
  color: var(--persian-blue-dark);
  text-decoration: underline;
}

.button-spacer {
  width: 0.5rem;
}

/* anchor as link */

a.link {
  color: var(--persian-blue);
  font-size: 1.6rem;
  cursor: pointer;
}

a.link:hover,
a.link:active {
  color: var(--persian-blue-dark);
  text-decoration: underline;
  outline: none;
}

/* miscellaneous */

.rtl {
  direction: rtl;
}

.icon {
  /* width: 1.5rem; */
  /* height: 1.5rem; */
}

.icon--arrow {
  margin: 0 0.2rem;
}

/***********************************
 * Form
 **********************************/

.form {
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
}

.form__body {
  padding: 1rem 2rem;
  flex: 1 1 auto;
  overflow-y: auto;
}

.form__text {
  margin-bottom: 1.5rem;
}

.form__action {
  cursor: pointer;
  align-self: center;
  padding-left: 0.2rem;
}

.form__action-group {
  display: flex;
}

.form__button-row {
  margin: 0.5rem 2rem 1.5rem 2rem;
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
}

.form__group {
  margin-bottom: 1rem;
}

.form__body p {
  margin: 2rem 0;
  text-align: justify;
}
