.Answer {
  padding: 0rem 0rem;
  height: 100%;
}

.Answer__correct {
  background-color: var(--persian-green);
}

.Answer__wrong {
  background-color: var(--persian-red-light);
}

.Answer__correct,
.Answer__wrong {
  color: var(--persian-white);
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  padding: 1rem 0;
  margin-top: 2rem;
}

.Answer__answerSide {
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
  padding: 1rem 1rem;
}

.Answer__questionSide {
  text-align: center;
  font-size: 1.8rem;
}

.Answer__thirdSide {
  font-size: 1.6rem;
  text-align: center;
  opacity: 0.4;
  margin-top: 1rem;
}

.Answer__buttons {
  margin: 2rem auto 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 40rem;
}
