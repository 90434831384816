.Profile {
  padding-top: 5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Profile__content {
  margin-top: 2rem;
  max-width: 50rem;
  padding: 0 2rem;
}
