.ProgressSummary__heading {
  margin-bottom: 2rem;
}

.ProgressSummary__row {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
}

.ProgressSummary__row:hover .ProgressSummary__bar {
  background: var(--persian-yellow);
  font-weight: bold;
}

.ProgressSummary__row:hover .ProgressSummary__row-label {
  color: var(--persian-blue-dark);
  font-weight: bold;
}

.ProgressSummary__row-label {
  flex: 0 0 2rem;
  text-align: right;
}

.ProgressSummary__bar {
  border: 0.1rem solid var(--persian-teal-dark);
  background: var(--persian-green);
  margin: 0.2rem 0.6rem 0.2rem 0.6rem;
  min-width: 0.3rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: width 1s ease-in-out;
}

/* .ProgressSummary__row-count {
  margin:
} */
