.Modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--box-shadow);

  transition: opacity 200ms ease-out;
  opacity: 0.01;
}

.Modal__overlay--after-open {
  opacity: 1;
}

.Modal__overlay--before-close {
  opacity: 0.01;
}

.Modal__window {
  box-shadow: 0 0 0.5rem 0.2rem var(--box-shadow);
  border: 0;
  border-top: 0.6rem solid var(--persian-teal);

  background-color: var(--persian-white);
  border-radius: 0;

  /* min-height: 10rem; */
  max-height: calc(90vh - 2rem);

  min-width: 25rem;
  max-width: 30rem;

  top: 30%;
  bottom: auto;
  left: 50%;
  right: auto;

  position: absolute;
  transform: translate(-50%, -50%);
  overflow: hidden;

  display: flex;
  flex-direction: column;

  transition: opacity 200ms ease-out, transform 200ms ease-out;
  opacity: 0.01;
}

.Modal__window--after-open {
  opacity: 1;
  transform: translate(-50%, -30%);
}

.Modal__window--before-close {
  opacity: 0.01;
  transform: translate(-50%, -50%);
}

.Modal__window:focus {
  outline: none;
}

.Modal__window > h1 {
  margin: 1rem 2rem;
  flex: 0 0 auto;
  font-weight: 400;
  font-size: 2rem;
}
