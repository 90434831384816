.Header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5rem;
  color: white;
  background-color: var(--persian-teal-light);
  box-shadow: 0 0 0.5rem 0.2rem var(--box-shadow);

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}

.Header .left {
  display: flex;
  align-items: center;
}

.Header .title {
  margin-left: 1rem;
}

.Header__link,
.Header__link:visited {
  color: var(--persian-white);
}

.Header__link:hover,
.Header__link:active {
  color: var(--persian-yellow);
}
