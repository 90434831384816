.Toolbar {
  height: 4rem;
  border-bottom: 0.1rem solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
  flex-shrink: 0;
}

.Toolbar > * {
  font-size: 1.2rem;
  height: 100%;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  background-color: #fff;
}

.Toolbar > *:hover,
.Toolbar > *:active {
  cursor: pointer;
  color: white;
  background-color: var(--persian-blue);
}

.Toolbar > *:not(:last-child) {
  border-right: 0.1rem solid #aaa;
}

.Toolbar > *:last-child {
  border-right: 0.1rem solid #aaa;
}

.Toolbar > *:first-child {
  border-left: 0.1rem solid #aaa;
}

.Toolbar .icon {
  margin-right: 0.6rem;
}
