.Study {
  padding-top: 5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Study__Unauthorized {
  max-width: 50rem;
  margin: 2rem auto;
  padding: 0 2rem;
}
