.Leaderboard {
  padding-top: 5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Leaderboard__content {
  margin-top: 2rem;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  /* max-width: 100rem; */
  /* padding: 0 2rem; */
}

.Leaderboard__table {
  flex-grow: 1;
  overflow: auto;
}

.Leaderboard__row {
  /* width: 100%; */
  width: fit-content;
  min-width: 100%;
  display: flex;
  height: 2.5rem;
  align-items: center;
}

.Leaderboard__header {
  /* width: 100%; */
  width: fit-content;
  min-width: 100%;
  display: flex;
  height: 2.5rem;
  align-items: center;
  font-weight: 600;
  border-bottom: 1px solid #ccc;
  white-space: nowrap;
}

.Leaderboard__row:nth-child(odd) {
  background-color: var(--persian-yellow-light);
}

.Leaderboard__row:hover {
  background-color: var(--persian-pale-blue);
}

.Leaderboard__cell {
  flex-basis: 20%;
  flex-grow: 1;
  min-width: 12rem;
}

.Leaderboard__header-cell {
  flex-basis: 20%;
  flex-grow: 1;
  min-width: 12rem;
  text-overflow: ellipsis;
}
